*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{
  font-family:'Outfit';
}


.formContainer {
    display: flex;
    align-items:center;
    justify-content:space-evenly; 
    height: 100vh;
    background-color: #fff;
    background-image: linear-gradient(#f3f6f4, #cdc4d7);
    background-size: cover;
    background-position: center;
  }
  
  
  .formSection {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: start;
    
    
  }

  .fieldContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    max-width: 500px;
    padding: 15px;
    border-radius: 20px
  }
  
  .title {
    font-weight: 600;
    font-size: 30px;
    color: #9f65b6; 
    padding: 10px;
  }

  .title-text{
    font-weight: 60;
    font-size: 25px;
    color: #9f65b6; 
    padding: 5px;

  }

  .input{
    height: 50px;
    width: 500px;
    padding: 20px;
    margin-bottom: 10px;
    font-size: 1rem;
    outline: none;
    border: none;
    border-radius: 20px;
  }

  .fieldContainer textarea{
    height: 120px;
    padding-top: 15px;
  }

  .input:focus{
    border: 2px solid #7396e4
  }

  .input::placeholder{
    color: #a9a9a9;
  }
  
  .submitButton {
    display: flex;
    align-items: center;
    padding: 20px 30px;
    color: white;
    background: linear-gradient(270deg,#7396e4,#9f65b6);
    border: none;
    border-radius: 30px;
    cursor: pointer;
  }
  
  .imageSection {
    display: flex;
    justify-content: center;
    align-items: left;
  }
  
  @media (max-width: 1024px) {
    .imageSection {
      display: none; /* Hide the image on smaller screens */
    }
    .input{
      width: 80vw;
    }
    .title-text{
      width: 80vw;
    }
  }
  

  