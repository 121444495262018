/* Import a professional font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

h1 {
    font-size: 2rem;
    overflow: hidden;
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: 0.1em;
    border-right: none; /* Ensure no extra border is applied */
    font-family: 'Roboto', sans-serif; /* Use the Roboto font */
    font-weight: 700; /* Set the font-weight to bold for a stronger appearance */
    color: #333; /* Darker color for a professional look */
}

.cursor {
    display: inline-block;
    border-right: 0.15em solid black; /* Blinking cursor */
    animation: fadeBlink 1s infinite; /* Updated to use fadeBlink animation */
}

@keyframes fadeBlink {
    0%, 100% { opacity: 0; } /* Start and end with transparent cursor */
    50% { opacity: 1; } /* Midway through, the cursor is fully visible */
}

