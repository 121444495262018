/* Importing Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@700&family=Lato:wght@400;700&display=swap');

.blog-container {
  max-width: 1000px; /* Appropriate width for readability */
  margin: 40px auto; /* Centers the blog on the page */
  padding: 20px; /* Spacing inside the container */
  box-shadow: 0 4px 15px rgba(0,0,0,0.15); /* Subtle shadow for depth */
  border-radius: 20px; /* Rounded corners */
  background-color: #fff; /* White background */
}

.blog-image {
  width: 50%; /* Full width of the container */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px 8px 0 0;
  object-fit: cover; /* Cover fit without distortion */
  display: block; /* Makes the image a block element */
  margin: 0 auto; /* Centers the image horizontally */
}

.blog-content {
  padding: 15px; /* Padding around text content for space */
}

.blog-title {
  font-family: 'Lora', serif; /* Elegant, classic font for titles */
  font-size: 2.5em; /* Large, bold title */
  font-weight: bold;
  color: #333; /* Dark grey color for text */
  margin-bottom: 20px; /* Space below the title */
}

.blog-date-author {
  font-size: 1em; /* Smaller text for meta information */
  color: #666; /* Light grey color */
  margin-bottom: 20px; /* Space before main text */
}

.blog-text {
  font-family: 'Lato', sans-serif; /* Simple, modern font for body text */
  font-size: 1.2em; /* Comfortable reading size */
  font-weight: normal;
  line-height: 1.8; /* Increased line height for better readability */
  color: #444; /* Grey color for text */
  text-align: justify; /* Justified text for a clean look */
}

/* Additional heading styles for other headers used within the content */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Cormorant Garamond', serif; /* Consistent elegant font for all headings */
  color: #333;
  margin-top: 30px; /* Added more space above each heading */
  margin-bottom: 15px; /* Space below each heading */
  letter-spacing: 0.02em; /* Consistent letter spacing for elegance */
}

